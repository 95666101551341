import { defineComponent, reactive } from 'vue';
import { useInit } from '@/services/shared/utils';
import { Form, Field } from 'vee-validate'; // https://vee-validate.logaretm.com/v4/guide/composition-api/validation

import * as yup from 'yup';
export default defineComponent({
  name: 'ResetPasswordComponent',
  components: {
    Form: Form,
    Field: Field
  },
  emits: ['onSubmitData'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    // Setup Default
    var _useInit = useInit(),
        base = _useInit.base; // Component reactive data


    var data = reactive({
      isLoading: false
    }); // Setup Form

    var formData = {
      password: '',
      confirmPassword: ''
    };
    var formValidation = yup.object({
      password: yup.string().label('Mật khẩu mới').required('Vui lòng điền mật khẩu mới').min(8, 'Độ dài tối thiểu 8 ký tự'),
      confirmPassword: yup.string().label('Xác nhận mật khẩu mới').required('Vui lòng điền xác nhận mật khẩu mới').oneOf([yup.ref('password')], 'Mật khẩu mới không khớp')
    });

    var onSubmitPassword = function onSubmitPassword(values) {
      emit('onSubmitData', values);
    };

    return {
      // Data
      data: data,
      // Form + Fields
      formValidation: formValidation,
      formData: formData,
      // Function
      onSubmitPassword: onSubmitPassword
    };
  }
});