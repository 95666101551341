import { defineComponent, reactive } from 'vue';
import { useInit } from '@/services/shared/utils';
import HomeFilterComponent from '@/components/features/home/HomeFilterComponent.vue';
import NewsHighlightsComponent from '@/components/features/news/NewsHighlightsComponent.vue';
import GroupCarouselComponent from '@/components/features/group/GroupCarouselComponent.vue';
export default defineComponent({
  name: 'HomePage',
  components: {
    HomeFilterComponent: HomeFilterComponent,
    NewsHighlightsComponent: NewsHighlightsComponent,
    GroupCarouselComponent: GroupCarouselComponent
  },
  setup: function setup() {
    // Setup Default
    var _useInit = useInit(),
        base = _useInit.base,
        isLoggedIn = _useInit.isLoggedIn;

    var data = reactive({}); // Computed
    // Function

    return {
      // Data
      data: data,
      // Computed
      isLoggedIn: isLoggedIn // Function

    };
  }
});