import { defineComponent, reactive } from 'vue';
import { useInit } from '@/services/shared/utils';
import { Form, Field } from 'vee-validate'; // https://vee-validate.logaretm.com/v4/guide/composition-api/validation

import * as yup from 'yup';
export default defineComponent({
  name: 'ForgotPasswordComponent',
  components: {
    Form: Form,
    Field: Field
  },
  emits: ['onSubmitData'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    // Setup Default
    var _useInit = useInit(),
        base = _useInit.base;

    var phoneRegExp = /((^(\+84|84|0|0084){1})(3|5|7|8|9))+([0-9]{8,9})$/; // Component reactive data

    var data = reactive({
      isLoading: false
    }); // Setup Form

    var formData = {
      username: ''
    };
    var formValidation = yup.object().shape({
      username: yup.string().label('Số điện thoại').min(10, 'Số điện thoại quá ngắn 0-11 số').max(11, 'Số điện thoại quá dài 0-11 số').matches(phoneRegExp, 'Số điện thoại không phù hợp').required('Vui lòng nhập số điện thoạ!')
    });

    var onForgotPassword = function onForgotPassword(values) {
      emit('onSubmitData', values);
    };

    return {
      // Data
      data: data,
      // Form
      formValidation: formValidation,
      formData: formData,
      // Function
      onForgotPassword: onForgotPassword
    };
  }
});