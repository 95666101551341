import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-731fdf4a"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "forgot-pwd-page"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_ForgotPasswordComponent = _resolveComponent("ForgotPasswordComponent");

  var _component_ForgotPasswordOtpComponent = _resolveComponent("ForgotPasswordOtpComponent");

  var _component_ResetPasswordComponent = _resolveComponent("ResetPasswordComponent");

  var _directive_loading = _resolveDirective("loading");

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [$setup.data.isShowForgotPasswordComponent ? (_openBlock(), _createBlock(_component_ForgotPasswordComponent, {
    key: 0,
    onOnSubmitData: $setup.onResetPwd
  }, null, 8
  /* PROPS */
  , ["onOnSubmitData"])) : $setup.data.isShowForgotPasswordOtpComponent ? (_openBlock(), _createBlock(_component_ForgotPasswordOtpComponent, {
    key: 1,
    phoneNumber: $setup.data.phoneOTP,
    onOnSubmitData: $setup.onVerifyOTP,
    onOnResendOTP: $setup.onResetPwd
  }, null, 8
  /* PROPS */
  , ["phoneNumber", "onOnSubmitData", "onOnResendOTP"])) : (_openBlock(), _createBlock(_component_ResetPasswordComponent, {
    key: 2,
    onOnSubmitData: $setup.onSubmitPassword
  }, null, 8
  /* PROPS */
  , ["onOnSubmitData"]))], 512
  /* NEED_PATCH */
  )), [[_directive_loading, $setup.data.isLoading]]);
}