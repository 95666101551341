import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-c6bdc170"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "home"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_HomeFilterComponent = _resolveComponent("HomeFilterComponent");

  var _component_NewsHighlightsComponent = _resolveComponent("NewsHighlightsComponent");

  var _component_GroupCarouselComponent = _resolveComponent("GroupCarouselComponent");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_HomeFilterComponent), _createVNode(_component_NewsHighlightsComponent), _ctx.isLoggedIn ? (_openBlock(), _createBlock(_component_GroupCarouselComponent, {
    key: 0
  })) : _createCommentVNode("v-if", true)]);
}